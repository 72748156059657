<template>
  <div class="Checkout__container relative mt-8">
    <div slim data-cy-region="checkout-payment">
      <form @submit="onSubmit">
        <!-- <Back class="px-5 lg:px-0" :to="isLoggedIn ? '/checkout/shipping' : '/checkout/guest-shipping'">
          <span class="text-primary-1-100 font-medium uppercase">
            {{ $t('backToShipping') }}
          </span>
        </Back> -->
        <div class="flex items-center justify-between px-5 lg:px-0">
          <h3 class="flex">
            <SvgIconDeliveryInfo width="28" height="28" class="mr-3" />
            <span class="font-bold text-xl text-black font-body"> {{ $t('shippingInformation') }}</span>
          </h3>

          <AppLink
            :to="`/checkout/${mode === 'guest' ? 'guest-shipping' : 'shipping'}`"
            class="flex items-center gap-x-1 font-medium text-sm"
          >
            <SvgIconEdit class="w-5 h-5 ml-2" />
            <span class="underline"> {{ $t('edit') }} </span>
          </AppLink>
        </div>

        <dl class="mt-4 text-sm grid grid-cols-1 px-5 lg:px-0">
          <dt class="text-gray-90 mt-7">{{ $t('shippingMethod') }}</dt>
          <dd v-if="isStorePickup" class="mt-2 text-primary-1-100">
            {{ $t('storePickup') }}
          </dd>
          <dd v-else class="mt-2 text-primary-1-100 font-medium">{{ $t('homeDelivery') }}</dd>

          <template v-if="address">
            <dt class="text-gray-90 mt-7">{{ $t('shippingAddress') }}</dt>
            <dd class="mt-2 text-primary-1-100 font-medium flex flex-col gap-y-1">
              <span>{{ address.firstname }} {{ address.lastname }}</span>
              <span>{{ address.telephone }}</span>
              <span v-if="address && address.street">{{ address.street[0] }}</span>
            </dd>
          </template>

          <!-- <template v-if="selectedShippingMethod">
          <dt class="text-primary-1-100 font-medium mt-7">{{ $t('deliveryType') }}</dt>
          <dd class="mt-2 text-gray-500">
            <div>
              {{ selectedShippingMethod.method_title }} , ( <Money :value="selectedShippingMethod.amount.value" /> )

              <span v-if="selectedShippingMethod.max_days && selectedShippingMethod.min_days">
                ,{{
                  $t('shippingMethods.deliverWithin', {
                    minDays: selectedShippingMethod.min_days,
                    maxDays: selectedShippingMethod.max_days,
                  })
                }}
              </span>
            </div>
          </dd>
        </template> -->
        </dl>

        <div class="mt-10 bg-primary-3-40 w-full h-1" />

        <template v-if="shippingMethods?.length > 1">
          <fieldset v-if="!isStorePickup && shippingMethods" class="mt-10 px-5 lg:px-0 flex flex-col">
            <legend class="text-lg-1 font-medium text-black [ flex items-center justify-center ]">
              <SvgIconShipping width="28" height="28" class="mr-3" />

              {{ $t('selectDeliveryType') }}
            </legend>

            <div class="relative mt-3">
              <div
                v-if="isUpdatingShippingMethod"
                class="[ absolute top-0 left-0 ] flex items-center justify-center w-full h-full"
              >
                <Spinner class="w-8 h-8" />
              </div>
              <RadioInput
                v-for="shippingMethod in shippingMethods"
                :id="`shippingMethod_${shippingMethod.carrier_code}`"
                :key="shippingMethod.carrier_code"
                name="shippingMethod"
                track-by="carrier_code"
                class="first:mt-0 mt-4"
                :class="{ 'opacity-50 pointer-events-none': loading }"
                :value="shippingMethod"
                :disabled="!shippingMethod.isActive"
                reversed
              >
                <template #labelDescription>
                  <div class="flex text-sm items-center text-gray-500">
                    <span v-if="shippingMethod.maxDays && shippingMethod.minDays" class="mr-2">
                      {{
                        $t('shippingMethods.deliverWithin', {
                          minDays: shippingMethod.minDays,
                          maxDays: shippingMethod.maxDays,
                        })
                      }}
                    </span>

                    <span v-if="shippingMethod.cutoffTime" class="mr-2">
                      {{ $t('shippingMethods.forOrders', { cutoffTime: shippingMethod.cutoffTime }) }}
                    </span>

                    <span class="flex items-center mr-2"> (+ <Money :value="shippingMethod.amount.value" />) </span>
                  </div>
                </template>

                <template #default>
                  <div class="flex flex-col text-black text-sm">
                    {{ shippingMethod.carrier_title }}

                    <span v-if="shippingMethod.minSubTotalFreeShipping" class="font-normal text-gray-500 items-center">
                      {{ t('shippingMethods.freeOver') }}
                      <Money :value="Number(shippingMethod.minSubTotalFreeShipping)" class="mx-2" />
                    </span>
                  </div>
                </template>
              </RadioInput>
            </div>
          </fieldset>
          <div class="mt-10 bg-primary-3-40 w-full h-1" />
        </template>

        <h1 class="mt-10 px-5 lg:px-0 text-lg-1 text-black font-medium font-body [ flex items-center ]">
          <SvgIconWallet class="mr-2" />
          {{ $t('payment') }}
        </h1>

        <fieldset class="flex flex-col px-5 lg:px-0">
          <CheckoutCoupons class="mt-6" @is-open="e => (isCouponsOpen = e)" />
        </fieldset>

        <fieldset class="flex flex-col px-5 lg:px-0">
          <legend></legend>
          <CheckoutGiftCard
            class="mt-6"
            @is-open="e => (isGiftCardOpen = e)"
            @change-payment-methods="e => (paymentMethods = e)"
          />
        </fieldset>
        <fieldset v-if="isLoggedIn" class="flex flex-col px-6 lg:px-0">
          <CheckoutDsquaresLoyaltyPoints
            class="mt-5 px-0"
            @is-open="e => (isGiftCardOpen = e)"
            @change-payment-methods="e => (paymentMethods = e)"
          />
        </fieldset>
        <div class="mt-10 bg-primary-3-40 w-full h-1" />

        <fieldset class="px-5 lg:px-0 mt-10 flex flex-col">
          <legend class="text-black font-medium">{{ codeFree ? '' : $t('selectPaymentMethod') }}</legend>

          <div class="mt-7 relative">
            <div
              v-if="isUpdatingPaymentState"
              class="[ absolute top-0 left-0 ] flex items-center justify-center w-full h-full"
            >
              <Spinner class="w-8 h-8" />
            </div>

            <PaymentStrategyDirector
              :class="{ 'opacity-50 pointer-events-none': loading }"
              :payment-methods="paymentMethods"
              :code-free="codeFree"
            />
          </div>
        </fieldset>
        <!-- <CheckboxInput v-model="agreedToTerms" rules="required" class="px-5 md:px-0 mt-16">
        <template #default>
          <p class="text-sm text-black">
            By continuing you agree to our
            <a href="#" class="text-black underline">Terms and Conditions</a> and
            <a href="#" class="text-black underline">Privacy Policy</a>
          </p>
        </template>
      </CheckboxInput> -->

        <div class="hidden md:flex">
          <AppButton
            type="submit"
            class="flex mt-20 w-full md:w-1/2 justify-center"
            :loading="isPlacingOrder || isSubmitting || loading"
            inverted
          >
            <span class="text-center uppercase"> {{ $t('placeOrder') }}</span>
          </AppButton>
        </div>
      </form>
    </div>

    <div class="mt-16 md:mt-0 flex flex-col self-start md:sticky md:top-0 px-5 md:px-0">
      <div class="flex flex-col items-center justify-center">
        <CheckoutPaymentSummary class="w-full" :payment-fees="paymentFees" />
        <div class="md:hidden w-full px-5 md:px-0">
          <AppButton
            type="button"
            class="flex md:hidden mt-13 w-full md:w-1/2 justify-center"
            :disabled="!meta.valid || isCouponsOpen || isGiftCardOpen"
            :loading="isPlacingOrder || isSubmitting || loading"
            inverted
            @click="onSubmit"
          >
            <span class="text-center uppercase"> {{ $t('placeOrder') }}</span>
          </AppButton>
        </div>
      </div>

      <CheckoutCartSummary class="mt-13 md:mt-0 pb-16 pt-8 lg:pb-24" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ShippingMethod } from '@robustastudio/e-commerce/common';
import type { AvailablePaymentMethod } from '@robustastudio/e-commerce/common';
import { useExtendPaymentFee } from '~/composables/cart';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { isFetchingCart, address, isStorePickup, itemCount } = useCartAttributes();

const isCouponsOpen = ref(false);
const isGiftCardOpen = ref(false);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { redirect } = useAppRouter();

definePageMeta({ layout: 'checkout' });

const { t: $t } = useI18n({ useScope: 'local' });
const { t } = useI18n({
  useScope: 'global',
});
const { isLoggedIn } = useAuth();

const { validationSchema } = useCheckoutPaymentValidationSchema();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { handleSubmit, values, errors, isSubmitting, meta } = useForm({
  validationSchema,
  initialValues: {
    shippingMethod: null,
    paymentMethod: null,
  },
});

useSetAutoShippingMethod();
useSetAutoPaymentMethod();
const route = useRoute();
const mode = computed<'customer' | 'guest'>(() => {
  return (route.query.mode as 'customer' | 'guest') || 'customer';
});
const { error } = useAlerts();

const onSubmit = handleSubmit(
  async ({ paymentMethod, shippingMethod }) => {
    try {
      await submitCheckout(
        ref(paymentMethod) as unknown as Ref<AvailablePaymentMethod>,
        ref(shippingMethod) as unknown as Ref<ShippingMethod>,
      );
    } catch (e) {
      console.error(e);
    }
  },
  onError => {
    if (onError.errors.paymentMethod) {
      error('error', onError?.errors?.paymentMethod);
    } else if (onError.errors.shippingMethod) {
      error('error', onError?.errors?.shippingMethod);
    }
  },
);

const {
  loading,
  submitCheckout,
  isPlacingOrder,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  paymentMethods: cartPaymentMethods,
  shippingMethods: originalShippingMethods,
  isInvalidOptions,
  isUpdatingShippingMethod,
  isUpdatingPaymentState,
  resetErrors,
} = useCheckout();

const paymentMethods = ref(cartPaymentMethods.value);
const { setPaymentMethodFees } = useExtendPaymentFee();
const paymentFees = ref<{
  title: string;
  fees: number;
}>({
  title: '',
  fees: 0,
});
const { cartState } = useStoredCart();

onMounted(() => {
  if (!cartState.value.cartId) {
    redirect('/cart');
  }
});
/**
 * update payment method when fetched and show them
 * check if it contains code free, set payment method to free
 * other wise set to cash on delivery
 */
const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');
const unWatch = watch(cartPaymentMethods, newValue => {
  paymentMethods.value = newValue;
  unWatch();
});

paymentMethods.value = cartPaymentMethods.value;
watch(paymentMethods, newValue => {
  if (newValue.length) {
    const free = newValue.find(e => e?.code === 'free');
    if (free) {
      selectedPaymentMethod.value = free as AvailablePaymentMethod;
    } else {
      selectedPaymentMethod.value = newValue.find(e => e?.code === 'robusta_accept_cc') as AvailablePaymentMethod;
    }
    nextTick();
  }
});
watch(selectedPaymentMethod, newSelected => {
  if (newSelected) {
    const code = selectedPaymentMethod?.value?.code ?? '';
    const data = setPaymentMethodFees(code);
    data.then(
      res =>
        (paymentFees.value = {
          title: newSelected.title,
          fees: res?.cart?.payment_fee ?? 0,
        }),
    );
  }
});

const codeFree = computed(() => paymentMethods.value?.some(e => e?.code === 'free'));

const shippingMethods = computed(() => {
  if (isStorePickup.value) {
    return originalShippingMethods.value.filter(method => method.carrier_code === 'pickuplocation');
  }

  return originalShippingMethods.value.filter(method => method.carrier_code !== 'pickuplocation');
});

watch(values, newValues => {
  if (isInvalidOptions.value && Object.keys(newValues).length && Object.keys(newValues)?.every(value => !!value)) {
    resetErrors();
  }
});

useAppSeoMeta({
  title: 'Payment Information',
  description: 'Checkout by filling your payment information and paying for your order',
});

/**
 * Auto Select the shipping method if there is only one
 */
const { setValue: setFieldValue } = useField('shippingMethod');
const isSet = ref(false);
watch(
  shippingMethods,
  newShippingMethods => {
    if (isSet.value) return;
    if (newShippingMethods.length === 1) {
      setFieldValue(newShippingMethods[0]);
      isSet.value = true;
    }
  },
  {
    immediate: true,
  },
);
</script>

<i18n>
{
  "en":{
    "placeOrder": "Place Order",
    "backToShipping": "Back To Shipping",
    "storePickup": "Store Pickup",
    "shippingInformation": "Shipping Information",
    "homeDelivery": "Home Delivery",
    "edit": "Edit",
    "shippingMethod": "Shipping Method",
    "shippingAddress": "Shipping Address",
    "deliveryType": "Delivery Type",
    "selectDeliveryType": "Select Delivery Type",
    "selectPaymentMethod": "Select Payment Method",
    "phone": "Phone",
    "name": "Name",
    "address": "Address",
    "payment": "Payment"
  },
  "ar": {
    "placeOrder": "بدء الطلب",
    "backToShipping": "العوده للتوصيل",
    "storePickup": "الاستلام من الفرع",
    "shippingInformation": "معلومات التوصيل",
    "homeDelivery": "التوصيل المنزلى",
    "edit": "تعديل",
    "shippingMethod": "طريقه التوصيل",
    "shippingAddress": "عنوان التوصيل",
    "deliveryType": "نوع التوصيل",
    "selectDeliveryType": "اختار طريقه التوصيل",
    "selectPaymentMethod": "اختار طريقه الدفع",
    "phone": "التلفون",
    "name": "الاسم",
    "address": "العنوان",
    "payment": "الدفع"
  }
}
</i18n>
