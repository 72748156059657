<template>
  <div class="relative flex flex-col">
    <h3 class="flex mb-5">
      <svg-icon-loyalty width="25" height="25" class="mr-2" />
      {{ $t('haveALoyaltyCode') }}
      <svg-icon-redeemed v-if="loyaltyVoucher?.code" width="22" height="22" class="ml-2" />
    </h3>
    <template v-if="!appliedCoupon.length">
      <button
        :aria-label="$t('haveALoyaltyCode')"
        :aria-pressed="Boolean(toggleCoupons)"
        type="button"
        @click="toggleCoupons = !toggleCoupons"
      >
        <span
          class="checkbox p-1 ml-auto flex justify-center items-center absolute top-0 right-0 rounded-md"
          :class="{
            active: toggleCoupons || appliedCoupon.length,
          }"
        >
          <svg-icon-check :class="{ invisible: !toggleCoupons, 'text-white': toggleCoupons }" class="stroke-current" />
        </span>
      </button>
    </template>
    <form @submit="onSubmit">
      <loyaltyVoucherCode
        v-if="toggleCoupons || loyaltyVoucher?.code"
        :applied-coupons="appliedCoupon"
        :loyalty-voucher="loyaltyVoucher"
        :loading="loading"
        class="block"
        @remove="() => removeCouponOnCart(appliedCoupon[0])"
      >
        <template #cancel>
          <button type="button" class="text-red-700 font-body underline ml-5" @click="toggleCoupons = !toggleCoupons">
            {{ $t('cancel') }}
          </button>
        </template>
      </loyaltyVoucherCode>
    </form>
  </div>
</template>
<script setup lang="ts">
import { object, string } from 'yup';
import { useApplyDsquaresLoyaltyPoints, useRemoveDsquaresLoyaltyPoints } from '~/composables/cart';

defineComponent({
  name: 'DsquaresLoyaltyPoints',
});

const { appliedDsquareVoucher: loyaltyVoucher } = useExtendedCartAttributes();
const { isFetching: isApplyingPromoCode, applyDsquaresLoyaltyPointsExecuter } = useApplyDsquaresLoyaltyPoints();
const { isFetching: isRemovingPromoCode, removeDsquaresLoyaltyPointsExecuter } = useRemoveDsquaresLoyaltyPoints();

const { error, success } = useAlerts();
const { t } = useI18n();
const { t: $t } = useI18n({
  useScope: 'local',
});

const { handleSubmit, setErrors, resetForm } = useForm({
  validationSchema: toTypedSchema(
    object({
      code: string()
        .required()
        .matches(/^[A-Za-z0-9]{12}$/, $t('CodeLenError')),
    }),
  ),
});

const toggleCoupons = ref(false);
const emit = defineEmits(['changePaymentMethods', 'isOpen']);

const onSubmit = handleSubmit(async ({ code }) => {
  try {
    const { PaymentMethods } = await applyDsquaresLoyaltyPointsExecuter(code);
    success(t('couponAdded').toString());
    toggleCoupons.value = false;
    emit('changePaymentMethods', PaymentMethods.value);
    resetForm();
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());
    setErrors({ code: t((e as Error).message).toString() });
  }
});

async function removeCouponOnCart(code: string) {
  try {
    const { PaymentMethods } = await removeDsquaresLoyaltyPointsExecuter(code);
    success(t('couponRemoved').toString());
    emit('changePaymentMethods', PaymentMethods.value);
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());
  }
}

watch(
  toggleCoupons,
  newValue => {
    emit('isOpen', newValue);
  },
  {
    immediate: true,
  },
);

const appliedCoupon = computed(() =>
  loyaltyVoucher.value && loyaltyVoucher.value !== null
    ? ([loyaltyVoucher.value.code].filter(Boolean) as string[])
    : [],
);

const loading = computed(() => isApplyingPromoCode?.value || isRemovingPromoCode?.value);
</script>
<i18n>
    {
      "en": {
        "haveALoyaltyCode": "Have a Loyalty Voucher?",
        "cancel": "Cancel",
        "couponRemoved": "voucher removed successfully",
        "couponAdded": "voucher added successfully",
        "notActive": "This Code is not active",
        "cartError":"Something went wrong!",
        "invalidLoyaltyVoucher": "Invalid voucher code",
        "customerNotAllowed": "You are not allowed to use this voucher code, please login with the correct account.",
        "connectionError": "Connection error, please try again later",
        "couponHasBeenUsed": "You have already used this voucher before, please make sure to enter a valid voucher",
        "couponHasExpired": "Voucher is expired",
        "invalidCoupon": "Invalid voucher code, please make sure to enter the 12 digits of the redeemed loyalty voucher",
        "CodeLenError": "Code must be 12 characters"
      },
      "ar": {
        "haveALoyaltyCode": "هل لديك  قسيمة ولاء؟",
        "cancel": "الغاء",
        "couponRemoved":"تم ازالة القسيمه بنجاح",
        "couponAdded": "تم اضافه القسيمه بنجاح",
        "notActive": "هذا الكود غير نشط",
        "cartError":"حدث خطأ ما",
        "invalidLoyaltyVoucher": "رمز القسيمة غير صالح",
        "customerNotAllowed": "لا يُسمح لك باستخدام رمز القسيمة هذا، يرجى تسجيل الدخول باستخدام الحساب الصحيح.",
        "connectionError": "خطأ في الاتصال، يرجى المحاولة لاحقًا",
        "couponHasBeenUsed": "لقد استخدمت هذه القسيمة من قبل، يرجى التأكد من إدخال قسيمة صالحة",
        "couponHasExpired": "القسيمة منتهية الصلاحية",
        "invalidCoupon": "رمز القسيمة غير صالح، يرجى التأكد من إدخال الاثناعشر أرقام للقسيمة",
        "CodeLenError": "الكود يجب ان يكون 12 حرف"
      }
    }
    </i18n>

<style lang="postcss" scoped>
.checkbox {
  @apply h-6 w-6  border border-primary-1-100 mr-2;

  &.active {
    @apply bg-primary-1-100 border-white;
  }
}

:deep(.TextInput__error) {
  bottom: -20px;
  z-index: 10;
  font-size: 11px;
}
</style>
